
.ReactModalPortal>div {
    opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
    align-items: center;
    display: flex;
    z-index: 1000;
    justify-content: center;
    transition: opacity 200ms ease-in-out;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
}

.plan-card {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .plan-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .plan-header {
    text-align: center;
  }
  
  .plan-header h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .plan-header p {
    font-size: 18px;
    color: #555;
  }
  
  .plan-details {
    margin-top: 20px;
  }
  
  .plan-details ul {
    list-style-type: none;
    padding: 0;
  }
  
  .plan-details ul li {
    margin-bottom: 5px;
  }
  
  /* Add more custom styles as needed */
  