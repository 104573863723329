.formContainer {
    padding: 20px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.formRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.formGroup {
    flex: 1;
    margin-right: 10px;
}

.formGroup:last-child {
    margin-right: 0;
}

.formGroup label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

.inputField,
.textareaField,
.selectField {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.textareaField {
    height: 38px;
}

.centeredButton {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.btnSubmit {
    width: 100%; /* Full width button */
    background-color: #6DBBFD;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
}

